import { defineStore } from 'pinia'
import Cookies from "js-cookie";

export const useCounterStore = defineStore('counter', {
  state: () => {
    return { 
      count: 0,
    }
  },
  actions: {
    increment(val = 1) {
      this.count += val
    },
  },
  getters:{
    doubleCount: (state) => state.count * 2
  }
})
export const useLocaleStore = defineStore('locale', {
  state: () => {
    return { 
      lang: Cookies.get("locale") ||"AR",
    }
  },
  actions: {
    changeLang(lang="AR"){
      Cookies.set("locale",lang)
      this.lang = lang
    }
  },
})
export const useDarkStore = defineStore('isDarkMode', {
  state: () => {
    return { 
      isDarkMode: Cookies.get("isDarkMode")  === 'true',
    }
  },
  actions: {
    changeIsDarkMode(isDarkMode=true){
      Cookies.set("isDarkMode",!isDarkMode)
      this.isDarkMode = !isDarkMode
    }
  },
})