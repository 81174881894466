import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import BlogView from '../views/BlogView.vue'
import ServicesView from '../views/ServicesView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import BlogDetails from '../views/BlogDetails.vue'
import ServiceDetails from '../views/ServiceDetails.vue'
import TrackOrder from '../views/TrackOrder.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'الرئيسية'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: 'من نحن'
    }
  },
  {
    path: '/track-order',
    name: 'track-order',
    component: TrackOrder,
    meta: {
      title: 'تتبع الطلب'
    }
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: BlogView,
    meta: {
      title: 'الاخبار'
    }
  },
  {
    path: '/blog/:id',
    name: 'BlogDetails',
    component: BlogDetails,
    props: true,
    meta: {
      title: 'الرئيسية'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      title: 'تواصل معنا'
    }
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView,
    meta: {
      title: 'الخدمات'
    }
  },
  {
    path: '/service/:id',
    name: 'ServiceDetails',
    component: ServiceDetails,
    props: true,
    meta: {
      title: 'تفاصيل الخدمة'
    }
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFoundView",
    component: NotFoundView,
    meta: {
      title: '404'
    }
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
router.beforeEach((to, from, next) => {
  const title = to.meta.title
  if (title) {
    document.title = title
  }
  next()
})
export default router
