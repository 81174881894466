<template>
    <div>
        <Services/>
    </div>
</template>
<script>
import Services from '@/components/Services.vue'
export default {
    name: 'ServiceView',
    components: {
        Services,
    }
}
</script>