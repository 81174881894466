<template>
    <div>
        <section class="bg-transparent dark:bg-primary-900 relative h-screen">
            <div class="max-w-screen-xl relative pt-24 lg:pt-28 px-4 py-8 mx-auto text-center lg:py-16 lg:px-12">
                <router-link to="/track-order" aria-label="Track Order Url"
                    class="inline-flex items-center justify-between px-1 py-1 pr-4 text-sm text-gray-700 bg-gray-100 rounded-full mb-7 dark:bg-gray-600 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                    role="alert">
                    <span
                        class="text-xs bg-primary-600 dark:bg-secondary-900 rounded-full font-semibold text-white px-4 py-1.5 mr-3 rtl:-mr-3 rtl:mx-2">{{
                            $t('new') }}</span>
                    <span class="text-sm font-medium">{{ $t("new_feature") }}</span>
                    <svg class="w-5 h-5 ml-2 rtl:rotate-180" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                </router-link>
                <h1
                    class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
                    {{ $t('company_name') }}</h1>
                <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-300">{{
                            $t('company_title') }}
                </p>
                <div dir="ltr"
                    class="flex flex-col mb-8 space-y-4 lg:mb-16 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                    <router-link to="/services" aria-label="Services Page"
                        class="inline-flex z-10 items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-primary-700 dark:bg-secondary-600 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-100 dark:focus:ring-primary-900">
                        <div class="flex justify-items-center">
                            <p class="rtl:order-1">{{ $t("learn_more") }}</p>
                            <svg class="w-5 h-5 mx-2 rtl:rotate-180" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </div>
                    </router-link>
                    <router-link to="/track-order" aria-label="Track Order Url"
                        class="inline-flex items-center z-10 justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-geo mx-2" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411" />
                        </svg>
                        {{ $t("track_order") }}
                    </router-link>
                </div>
            </div>
            <img src="../assets/img/boxes.png"
                class="hero-img lg:top-64 right-0 block w-xl-auto w-96 sm:w-100 lg:absolute ltr:ms-0" 
                alt="Hero Image">
            <div class="hidden lg:block">
                <img src="../assets/img/full-service.png" 
                class="top-56 left-0 block w-xl-auto w-7/12 sm:w-100 lg:absolute"
                    alt="Hero Image">
            </div>

        </section>
    </div>
</template>