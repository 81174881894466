<template>
    <div class="w-full px-4 md:w-1/2 lg:w-1/3">
        <div class="w-full mb-10">
            <div class="mb-2 overflow-hidden rounded-lg">
                <img :src="item.image" alt="image" class="w-full" />
            </div>
            <div>
                <span
                    class="inline-block px-4 py-1 text-xs font-semibold leading-loose text-center text-white dark:text-primary-100 rounded bg-primary-600 dark:bg-white ">
                    {{ item.created_at }}
                </span>
                <h3>
                    <router-link :to="`/blog/${item.id}`"class="inline-block mb-2 text-xl font-semibold 
                        dark:text-white hover:text-primary-600 sm:text-2xl 
                        lg:text-xl xl:text-2xl">
                        {{ item.title }}
                    </router-link>
                </h3>
                <p class="text-base text-primary-600 dark:text-gray-100" v-html="truncateContent(item.content, 100)">
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    setup() {
        const truncateContent = (content, length) => {
            // Check if the content length exceeds the specified length
            if (content.length > length) {
                // Truncate the content and append '...' at the end
                return content.slice(0, length) + '...';
            } else {
                return content;
            }
        };

        return {
            truncateContent
        };
    }
}
</script>
