<template>
    <div>
    <nav class="bg-white dark:bg-primary-800 fixed w-full z-50 top-0 start-0 shadow border-gray-200 dark:border-gray-600">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <router-link to="/" aria-label="Home Page" class="flex items-center space-x-3 rtl:space-x-reverse">
            <img v-if="isDarkMode" loading="lazy" src="@/assets/img/logo_dark.png" class="h-8" alt="Talia Logo" />
            <img v-else loading="lazy" src="@/assets/img/logo.png" class="h-8" alt="Talia Logo" />
            <!-- <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Talia</span> -->
        </router-link>
        <div class="flex md:order-2 space-x-3 md:space-x-1 rtl:space-x-reverse">
            <button v-if="$i18n.locale === 'AR'" @click="changeLocale('EN')" type="button"
                class="inline-flex items-center font-medium justify-center px-4 py-2 text-gray-900 dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                {{ $t("english") }}
            </button>
            <button v-if="$i18n.locale === 'EN'" @click="changeLocale('AR')" type="button"
                class="inline-flex items-center font-medium justify-center px-4 py-2 text-gray-900 dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                {{ $t("arabic") }}
            </button>
            <router-link aria-label="Track your order" to="/track-order" class="text-white action-button bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-secondary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >{{ $t('track_order') }}</router-link>
            <button @click="toggleDarkMode()" type="button" 
                class="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-secondary-color dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white">
                <template v-if="isDarkMode" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                        <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/>
                    </svg>
                    <p class="mx-2 hidden sm:inline">{{ $t('light') }}</p>

                </template>
                <template v-else >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                        <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278"/>
                    </svg>
                    <p class="mx-2 hidden sm:inline">{{ $t('dark') }}</p>

                </template>
            </button>
            <button data-collapse-toggle="navbar-sticky" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
        </div>
        <div class="items-center  sm:ms-32 justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
        <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-primary-800 md:dark:bg-primary-800 dark:border-gray-700">
            <li>
            <router-link to="/" aria-label="Home Page" class="block py-2 px-3 font-semibold text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-700 md:p-0 md:dark:hover:text-primary-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >{{ $t('home') }}</router-link>
            </li>
            <li>
            <router-link to="/about" aria-label="About Page" class="block py-2 px-3 font-semibold text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-700 md:p-0 md:dark:hover:text-primary-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >{{ $t('about') }}</router-link>
            </li>
            <li>
            <router-link to="/services" aria-label="Services Page" class="block py-2 px-3 font-semibold text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-700 md:p-0 md:dark:hover:text-primary-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >{{ $t('services') }}</router-link>
            </li>
            <li>
            <router-link to="/blogs" aria-label="Blogs Page" class="block py-2 px-3 font-semibold text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-700 md:p-0 md:dark:hover:text-primary-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >{{ $t('blog') }}</router-link>
            </li>
            <li>
            <router-link to="/contact" aria-label="contacts Page" class="block py-2 px-3 font-semibold text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-700 md:p-0 md:dark:hover:text-primary-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >{{ $t('contact us') }}</router-link>
            </li>
            <!-- <li>
            <router-link to="/contact" aria-label="contacts Page" class="block py-2 px-3 font-semibold text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-700 md:p-0 md:dark:hover:text-primary-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            >{{ $t('contact') }}</router-link>
            </li> -->
        </ul>
        </div>
        </div>
    </nav>
    </div>
</template>

<script>
import { useLocaleStore } from '@/store';
import { useDarkStore } from '@/store';
import { ref , onMounted } from 'vue';
import Cookies from 'js-cookie';

export default {

    setup() {
        const store = useLocaleStore();
        const darkStore = useDarkStore();
        const isDarkMode = ref(true) 
        const changeLocale = (lang) => {
        store.changeLang(lang);
        // i18n.global.locale = lang;
        window.location.reload();
        }
        const toggleDarkMode = () => {
            isDarkMode.value = !isDarkMode.value;
            document.body.classList.toggle('dark', isDarkMode.value);
            document.body.classList.toggle('dark-body', isDarkMode.value);
            const newDarkModeValue = isDarkMode.value;
            darkStore.changeIsDarkMode(newDarkModeValue)
            Cookies.set("isDarkMode", newDarkModeValue);
        }
        onMounted(() => {
            const storedDarkModeValue = Cookies.get("isDarkMode");      
            if (storedDarkModeValue === "true" || storedDarkModeValue === undefined) {
                isDarkMode.value = true;
                document.body.classList.add('dark');
                document.body.classList.add('dark-body');
                Cookies.set("isDarkMode", true);
            } else {
                isDarkMode.value = false;
                document.body.classList.remove('dark');
                document.body.classList.remove('dark-body');
                Cookies.set("isDarkMode", false);
            }
        })
        return {
            store, 
            changeLocale, 
            toggleDarkMode,
            isDarkMode,
        };
    }
}
</script>
