<template>
  <div class="about">
    <About/>
  </div>
</template>
<script>
import About from '@/components/About.vue'
export default {
  components:{
    About,
  }
}

</script>
