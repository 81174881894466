<template>
    <div>
        <section class="mt-5 pt-20 pb-10 lg:pt-[120px] lg:pb-20 bg-gray-50 dark:bg-primary-50 rounded-2xl">
            <div class="container mx-auto">
                <div class="flex flex-wrap justify-center -mx-4">
                    <div class="w-full px-4">
                        <div class="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                            <span class="block mb-2 text-lg font-semibold text-primary-600 dark:text-gray-50"> 
                                {{ $t('News') }} </span>
                            <h2 class="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] dark:text-white">
                                {{ $t('Our Recent News') }}
                            </h2>
                            <!-- <p class="text-base text-body-color dark:text-dark-6">
                                There are many variations of passages of Lorem Ipsum available but the majority have
                                suffered alteration in some form.
                            </p> -->
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap -mx-4 text-start">
                    <BlogItem v-for="(item, index) in blogItems" :key="index" :item="item" />
                </div>
            </div>
        </section>

    </div>
</template>
<script setup>
import BlogItem from '@/components/BlogItem.vue'
import axios from 'axios';
import { ref,onMounted } from 'vue'
const blogItems = ref([])
    onMounted(async () => {
            try {
                const response = await axios.get('blogs/');
                blogItems.value = response.data;
            } catch (error) {
                console.error("Error fetching blogs:", error);
            }
        });
</script>