<template>
    <div>

        <section class="px-4 py-10 md:py-12 bg-slate-100-800 sm:px-6 lg:px-8">
        <div
          class="max-w-xl mx-auto text-center md:max-w-2xl lg:text-left lg:max-w-screen-xl"
        >
          <div class="grid w-full gap-6 mt-6 lg:grid-cols-2">
            <div class="lg:col-span-3">
              <h2
                data-aos="zoom-in" data-aos-duration="600"
                class="text-3xl text-center font-extrabold text-gray-900 dark:text-white sm:text-4xl md:text-5xl"
              >
              {{ $t('company_name') }}
              </h2>
            </div>
            <div class="lg:col-span-2">
              <p class="text-xl text-gray-800 text-center dark:text-gray-200"
              data-aos="zoom-in" data-aos-duration="800">
              {{ $t('company_desc') }}
            </p>
            </div>
          </div>
        </div>
        <div
          class="grid justify-center w-full gap-12 mx-auto mt-12 lg:grid-cols-2 lg:gap-8 lg:mt-16 lg:max-w-screen-xl xl:max-w-screen-xl lg:justify-start"
        >
        
        <div>
            <div v-for="service in services" 
            :key="service.id"
            :class="service.id % 2 !== 0 ? 'xl:pr-18 2xl:pr-28' : ''"
            class="w-full mt-8 lg:mt-5 xl:mt-8 2xl:mt-10 xl:pr-18">
        
            <div class="w-full"
            data-aos="fade-start" :data-aos-duration="300*service.id">
                <div class="max-w-lg mx-auto lg:mr-auto xl:mx-auto">
                <div
                    class="relative flex w-full px-8 py-6 shadow-lg rounded-3xl bg-white dark:bg-primary-150 sm:px-10"
                >
                    <div class="text-center sm:flex sm:text-start">
                    <div class="w-full sm:w-1/5">
                        <div
                        class="flex items-center justify-center w-12 h-12 mx-auto bg-gradient-to-r rounded-2xl text-gray-100 sm:mx-0 from-gray-100 to-primary-150 dark:from-secondary-600 dark:to-secondary-600"
                        >
                        <div v-html="getSvgIcon(service.icon)"></div>
                        </div>
                    </div>
                    <div class="w-full mt-3 sm:mt-0">
                        <h5 class="text-lg text-start font-semibold text-gray-900 dark:text-white">
                        {{ service.title }}
                        </h5>
                        <p class="mt-1 text-start text-base text-gray-700 dark:text-gray-200">
                        {{ service.desc }}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            </div>
        </div>
          <!-- Image collage -->
          <div
            class="flex items-center justify-center max-w-screen-md lg:max-w-full"
          >
            <div class="grid h-full grid-flow-col grid-rows-2 gap-4">
              <div class="row-span-2 shadow-xl rounded-3xl">
                <img
                  src="../assets/img/ship-night.jpg"
                  data-aos="zoom-in"
                  class="object-cover object-right w-full h-full rounded-3xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</template>
<script>

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
    name: 'WhatWeDo',
    components: {
        
    },
    setup() {
        const { t } = useI18n();
        const services = ref([
            {
                "id" : 1,
                "title" : t('vision'),
                "desc" : t('vision_desc'),
                "image" : "",
                "icon":"icon1",
            },
            {
                "id" : 2,
                "title" : t('mission'),
                "desc" : t('mission_desc'),
                "image" : "",
                "icon":"icon2",
            },
            {
                "id" : 3,
                "title" : t('message'),
                "desc" : t('message_desc'),
                "image" : "",
                "icon":"icon3",
            },
            {
                "id" : 3,
                "title" : t('value'),
                "desc" : t('value_desc'),
                "image" : "",
                "icon":"icon4",
            },
        ]);
        const getSvgIcon = (icon) => {
            switch(icon) {
                case 'icon1':
                return `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="-3 -3 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                        </svg>`;
                case 'icon2':
                return `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="-3 -3 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a2 2 0 0 0-.453-.618A5.98 5.98 0 0 1 2 6m6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1"/>
                        </svg>`;
                case 'icon3':
                return `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="-3 -3 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M2.678 11.894a1 1 0 0 1 .287.801 11 11 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8 8 0 0 0 8 14c3.996 0 7-2.807 7-6s-3.004-6-7-6-7 2.808-7 6c0 1.468.617 2.83 1.678 3.894m-.493 3.905a22 22 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a10 10 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105"/>
                        </svg>`;
                case 'icon4':
                return `<svg xmlns="http://www.w3.org/2000/svg" fill="#" class="w-6 h-6" viewBox="-3 -3 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
                        </svg>`;
                default:
                return '';
            }
            };

        return {
            services,
            getSvgIcon,
        };
    },
}
</script>