import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import '@/assets/css/main.css'
import 'aos/dist/aos'
import 'aos/dist/aos.css'
import '@/axios'
import AR from '@/locale/ar.json'
import EN from '@/locale/en.json'
import { createPinia } from 'pinia'
import 'flowbite/dist/flowbite'
import { createI18n } from 'vue-i18n'
import VueGtag from "vue-gtag";
import AOS from 'aos'


AOS.init();

const app = createApp(App)
app.use(createPinia())

import { useLocaleStore } from '@/store/index'
const store = useLocaleStore()

export const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale : store.lang || "AR",
    messages:{
        EN: EN,
        AR: AR,
    }
})

app.use(i18n)
app.use(router)
// app.use(VueGtag, {
//     appName:'Fazaa',
//     pageTrackerScreenviewEnabled:true,
//     config: { id: "G-QPBHENTNK8" },
// })
app.mount('#app')
