<template>
    <div>
        <div class="">
            <footer class=" bg-white sm:m-0 dark:bg-primary-50 rounded-2xl relative z-10 pt-20 pb-10 lg:pt-[120px] lg:pb-20">
            <div class="container mx-auto">
                <div class="w-full px-4">
                        <div class="w-full mb-10">
                            <router-link to="/" class="mb-6 inline-block max-w-[120px] lg:max-w-[160px]">
                                <img loading="lazy" src="../assets/img/logo_dark.png" 
                                class=" hidden dark:block"
                                    alt="Talia Logo" />
                                <img loading="lazy" src="../assets/img/logo.png" 
                                class="max-w-full dark:hidden"
                                    alt="Talia Logo" />
                            </router-link>
                            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-400 lg:my-8" />
                        </div>
                    </div>
                <div class="flex flex-wrap -mx-4">

                    <div class="w-full px-4 sm:w-1/2 lg:w-2/5">
                        <div class="w-full mb-10">
                            <h4 class="text-xl font-semibold text-primary-600  dark:text-white mb-5">
                                {{ $t("about") }}
                            </h4>
                            <ul class="space-y-3">
                                <li class="block">
                                    <router-link to="about/" class="text-primary-600  dark:text-white">
                                        {{ $t('mission') }}
                                    </router-link>
                                </li>
                                <li class="block">
                                    <router-link to="about/" class="text-primary-600  dark:text-white">
                                        {{ $t('value') }}
                                    </router-link>
                                </li>
                                <li class="block">
                                    <router-link to="about/" class="text-primary-600  dark:text-white">
                                        {{ $t('vision') }}
                                    </router-link>
                                </li>
                                <li class="block">
                                    <router-link to="about/" class="text-primary-600  dark:text-white">
                                        {{ $t('message') }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="w-full px-4 sm:w-1/2 lg:w-2/12">
                        <div class="w-full mb-10">
                            <h4 class="text-xl font-semibold text-primary-600  dark:text-white mb-5">
                                {{ $t("services") }}
                            </h4>
                            <ul class="space-y-3 ">
                                <li v-for="(item, index) in services" :key="index" class="block">
                                    <router-link :to="'service/'+item.id" class="text-primary-100 dark:text-gray-100">
                                        <span class="rtl:hidden block">{{ item.name_en }}</span>
                                        <span class="ltr:hidden block">{{ item.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="w-full px-4 sm:w-1/2 lg:w-2/12">
                        <div class="w-full mb-10">
                            <h4 class="text-xl font-semibold text-primary-600  dark:text-white mb-5">
                                {{ $t('Quick Links') }}
                            </h4>
                            <ul class="space-y-3">
                                <li class="block">
                                    <router-link to="/" class="text-primary-100 dark:text-gray-100">
                                        {{ $t('home') }}
                                    </router-link>
                                </li>
                                <li class="block">
                                    <router-link to="services" class="text-primary-100 dark:text-gray-100">
                                        {{ $t('services') }}
                                    </router-link>
                                </li>
                                <li class="block">
                                    <router-link to="blogs" class="text-primary-100 dark:text-gray-100">
                                        {{ $t('blog') }}
                                    </router-link>
                                </li>
                                <li class="block">
                                    <router-link to="contact" class="text-primary-100 dark:text-gray-100">
                                        {{ $t('contact us') }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="w-full px-4 sm:w-1/2 lg:w-3/12">
                        <div class="w-full mb-10">
                            <h4 class="text-xl font-semibold text-primary-600  dark:text-white mb-5">
                                {{ $t('Follow Us') }}
                            </h4>
                            <div class="flex items-center justify-center mb-6 gap-2">
                                <a class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full
                        bg-gradient-to-r from-[#833ab4] via-[#fd1d1d] to-[#fcb045] text-white"
                            href="https://www.instagram.com/talialtd.ar/" >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            viewBox="2 2 20 20"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round" >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="4" y="4" width="16" height="16" rx="4" />
                            <circle cx="12" cy="12" r="3" />
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 
                        transition duration-300 ease-in-out rounded-full 
                        bg-[#4267B2]
                        text-white"
                            href="https://www.facebook.com/talialtd.ar">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            viewBox="2 2 20 20"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                            />
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 
                        ease-in-out rounded-full 
                        bg-black
                        text-white" href="https://www.facebook.com/talialtd.ar">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            viewBox="0 0 16 16"
                            stroke-width="0.2"
                            stroke="currentColor"
                            fill="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 
                        ease-in-out rounded-full 
                        bg-[#0077B5]
                        text-white"
                            href="https://www.facebook.com/talialtd.ar">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            viewBox="0 0 16 16"
                            stroke-width="0.2"
                            stroke="currentColor"
                            fill="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                            </svg>
                        </a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-400 lg:my-8" />
                <p class="text-sm font-semibold text-gray-600 sm:text-center dark:text-gray-300">
                                © 2024 <a href="https://talialtd.com" aria-label="Company Website Url"
                                    class="hover:underline dark:text-white">{{ $t("company_name") }}™</a>{{
                                $t("copy_rights") }}
                </p>
                <p class="text-xs mt-2  text-gray-600 sm:text-center dark:text-gray-300">
                    {{ $t('Developed by') }}<a href="https://eshaibani.com/" target="_blank"> <span class="mx-1 text-gray-600 dark:text-white">{{ $t('Ebrahim Hasan') }}</span></a>
                </p>
            </div>
            <div>
                <span class="absolute left-0 bottom-0 z-[-1]">
                    <svg width="217" height="229" viewBox="0 0 217 229" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M-64 140.5C-64 62.904 -1.096 1.90666e-05 76.5 1.22829e-05C154.096 5.49924e-06 217 62.904 217 140.5C217 218.096 154.096 281 76.5 281C-1.09598 281 -64 218.096 -64 140.5Z"
                            fill="url(#paint0_linear_1179_5)" />
                        <defs>
                            <linearGradient id="paint0_linear_1179_5" x1="76.5" y1="281" x2="76.5" y2="1.22829e-05"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#e47916" stop-opacity="0.4" />
                                <stop offset="1" stop-color="#fff" stop-opacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>
                </span>
                <span class="absolute top-10 right-10 z-[-1]">
                    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M37.5 -1.63918e-06C58.2107 -2.54447e-06 75 16.7893 75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 -7.33885e-07 58.2107 -1.63918e-06 37.5C-2.54447e-06 16.7893 16.7893 -7.33885e-07 37.5 -1.63918e-06Z"
                            fill="url(#paint0_linear_1179_4)" />
                        <defs>
                            <linearGradient id="paint0_linear_1179_4" x1="-1.63917e-06" y1="37.5" x2="75" y2="37.5"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#e47916" stop-opacity="0.31" />
                                <stop offset="1" stop-color="#fff" stop-opacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>
                </span>
            </div>
        </footer>
        </div>

    </div>
</template>
<script>
import axios from 'axios';
import { ref,onMounted } from 'vue'
export default {
    setup() {
        const showCards = ref('showCards')
        const activeClasses = ref('bg-primary text-white')
        const inactiveClasses = ref('text-body-color dark:text-gray-700 hover:bg-primary hover:text-white')
        onMounted(async () => {
            try {
                const response = await axios.get('services/');
                services.value = response.data;
            } catch (error) {
                console.error("Error fetching services:", error);
            }
        });
        const services = ref([])
        return {
            showCards,
            activeClasses,
            inactiveClasses,
            services,
        }
    }
}
</script>